.private-layout {
  min-height: 100vh;
  .header-layout {
    height: 80px;
    background-color: white !important;
  }
  .content-layout {
    min-height: calc(100vh - 80px);
    overflow-x: hidden;
  }
}
