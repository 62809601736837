.container-indicator-slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container-list-datepicker {
  div {
    cursor: pointer;
    user-select: none;
    padding: 1rem 10px;
    border-bottom: 1px solid #DBDDE4;
    &:hover {
      background-color: #D9EAF8;
      span {
        color: @primary !important;
      }
    }
  }
}
.item-date-active {
  background-color: #D9EAF8;
  span {
    color: @primary !important;
  }
}
.date-picker-modal-btn {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 5px 10px;
}
